@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  font-family: Montserrat;
  background-color: #1c1c1c;
}

@font-face {
  font-family: "Montserrat"; //This is what we are going to call
  src: url("./assets/fonts/Montserrat.ttf");
  font-display: swap;
}

@font-face {
  font-family: "PricesFont"; //This is what we are going to call
  src: url("./assets/fonts/font-preturi.otf");
  font-display: swap;
}

.mat-mdc-text-field-wrapper{
  border-radius: 16px !important;
}

p{
  line-height: 1.5;
  font-weight: 200;
}

button, a{
  letter-spacing: 0 !important;
  text-transform: uppercase !important;
  &[mat-raised-button]{
    border-radius: 24px !important;
      font-weight: bold !important;
      padding: 20px 28px;
  }

  &.primary{
    background-color: var(--color-primary) !important;
    color: black !important;
  }

  &.secondary{
    background-color: black !important;
    color: white !important;
  }
  
}

.cdk-overlay-dark-backdrop{
  background: rgba(0, 0, 0, .82) !important;
}

body {
  --color-primary: #f9a653;
  --bg-color-primary: #1c1c1c;
  --bg-color-secondary : #2f2f2f;
  --bg-color-tertiary : #0c0b0d;
  --mdc-filled-text-field-caret-color : var(--color-primary);
  --mdc-filled-text-field-focus-active-indicator-color : var(--color-primary);
  --mdc-filled-text-field-focus-label-text-color : var(--color-primary);
  --mdc-outlined-text-field-caret-color : var(--color-primary);
  --mdc-outlined-text-field-focus-outline-color:var(--color-primary);
  --mat-form-field-focus-select-arrow-color:var(--color-primary);
  --mat-option-selected-state-label-text-color:var(--color-primary);
  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    background: var(--color-primary) !important;
  }

  h3,
  p,
  h2 {
    margin: 0;
  }
}

.flex-container{
  max-width: 1370px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.user-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  height: 260px;
  border-radius: 8px;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
  .image {
    height: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 8px;
    .gradient {
      position: absolute;
      border-radius: 8px;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 100%;
      background-image: linear-gradient(
        rgba(255, 0, 0, 0),
        rgb(0, 0, 0)
      );
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: column;

      .content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        padding-bottom: 24px;
        text-transform: none;
        h3 {
          margin: 0;
          display: flex;
          gap: 8px;
          align-items: center;
          color: var(--color-primary);
          font-size: 14px;
          @media(min-width : 1024px){
            font-size: 20px;
          }
        }
        p {
          font-size: 12px;
          @media(min-width : 1024px){
            font-size: 16px;
          }
        }
      }
    }
  }
  
}

.custom-dialog > .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: #121212;
  color: white;
  border: 1px solid #2d2d2d;
  border-radius: 16px;

}

.mat-mdc-menu-item{
  &.selected{
    color: var(--color-primary) !important;
    mat-icon{
      color: var(--color-primary) !important;
    }
  }
}


.header{
  height: 56px;
  width: 100%;
  font-size: 1.3rem;
  color: white;
  text-align: center;
  position: static;
  top: 0;
  left: 0;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 8px 16px;
  box-sizing: border-box;
}

.blur {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  opacity: 0.8;
  filter: blur(200px);
  background-color: var(--color-primary);
  z-index: 0;
  &.top-left {
    top: 0;
    left: 0;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
  }

  &.center-top {
    top: 0;
    height: 200px;
    left: 20%;
  }

  &.center-bottom {
    bottom: 0;
    height: 200px;
    left: 60%;
  }
}

p {
  color: white;
}

.chip{
  padding: 8px 16px;
    background-color: grey;
    border-radius: 16px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

}
  swiper-container::part(button-next) {
    color: var(--color-primary);
  }

  swiper-container::part(button-prev) {
    color: var(--color-primary);
  }

  swiper-container::part(bullet-active) {
    
    background-color: var(--color-primary) ;
  }